import React from 'react'
import ReactVivus from 'react-vivus';
import svg from '../../assets/images/nameAnimation.svg';
import './LeftBanner.css';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact, FaGithub } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs } from "react-icons/si";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["UI/UX Designer.", "Software Engineer.", "Front-end Developer.", "Data Scientist.", "Computer Scientist."],
    loop: 1,
    typeSpeed: 100,
    deleteSpeed: 0,
    delaySpeed: 2000,
  });

  const [WIP] = useTypewriter({
    words: ["..."],
    loop: false,
    typeSpeed: 300,
    deleteSpeed: 0,
    delaySpeed: 1000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-10">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal" style={{ marginBottom: '10px' }}>⚠️ WORK IN PROGRESS{WIP}</h4>
        {/* <h4 className=" text-lg font-normal" style={{ marginBottom: '10px' }}>WELCOME TO MY PORTFOLIO</h4> */}
        {/* <span>{WIP}</span> */}
        <div className="flex sm:flex-row flex-col items-start">
          <h1 className="text-6xl font-normal text-white" style={{ display: 'flex', marginRight: '20px', marginBottom: '10px' }}>
            I am
          </h1>
          <ReactVivus
            id="alessioAnimation"
            option={{
              file: svg,
              type: 'oneByOne',
              animTimingFunction: 'EASE',
              duration: 200,
              // onReady: console.log
            }}
            style={{ height: '52px', alignSelf: 'top' }}
          // callback={console.log}
          />
        </div>
        <h2 className="text-4xl font-normal text-white">
          a <span>{text}</span>
          {/* <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          /> */}
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        Computer Science graduate from King's College London, specialising in Software Engineering (SWE), Data Analysis, 
        Geographic Information Systems (GIS), and User Interface/User Experience (UI/UX) design.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <a href="https://github.com/kclalessio" className="bannerIcon" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
            <a href="https://linkedin.com/in/alessiokcl/" className="bannerIcon" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        {/* <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiNextdotjs />
            </span>
            <span className="bannerIcon">
              <SiTailwindcss />
            </span>
            <span className="bannerIcon">
              <SiFigma />
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LeftBanner