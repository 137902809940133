import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col items-center gap-10 lgl:gap-20"
    >
      <div class className="max-w-[600px]">
        <div className="pt-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2019 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">

          {/* University education */}
          <ResumeCard
            title="BSc Hons Computer Science"
            subTitle="King's College London (2020 - 2023)"
            result="1st Class"
            des="Modules include: Software Engineering Group Project (Python, Django, React), 
            Software Measurement and Testing, 
            Individual Project (Dissertation, Data Analysis, Python),  
            Human-Computer Interaction (UI/UX Research), 
            Programming Practice and Applications (Java), 
            Data Structure"
          />

          {/* A-level education */}
          <ResumeCard
            title="Maths, Physics & Italian - A-Level"
            subTitle="Oxford Sixth Form College (2019 - 2020)"
            result="AAA"
            des="This educational journey has honed my analytical thinking, problem-solving, and language skills."
          />

        </div>
      </div>
    </motion.div>
  );
};

export default Education;
