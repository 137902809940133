import React from 'react'

const FooterBottom = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full py-10 flex flex-col items-center">
      <a href="https://github.com/noorjsdivs/portfolioone" target="_blank" rel="noopener noreferrer" className="text-center text-gray-500 text-base">
        Credit: Noor Mohammad
      </a>
      <p className="text-center text-gray-500 text-base">
        © {currentYear}. All rights reserved by Alessio Wu
      </p>
    </div>
  );
}

export default FooterBottom