import React from 'react'
import { motion } from 'framer-motion';
import { FaPython, FaJava, FaGithub, FaTrello, FaHtml5, FaReact, FaFigma, FaBootstrap } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { DiScala, DiMysql } from "react-icons/di";
import { SiDjango } from "react-icons/si";
import { IoLogoFigma } from "react-icons/io5";
import { SiMui, SiWireshark } from "react-icons/si";
import { GrMysql } from "react-icons/gr";

const Skills = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1, transition: { duration: 0.5 } }}
			className="w-full flex flex-col md:flex-row gap-10 lgl:gap-20"
		>

			{/* Programming Languages */}
			<div className="w-full lgl:w-1/2 px-5">
				<div className="py-12 font-titleFont flex flex-col gap-4">
					<p className="text-sm text-designColor tracking-[4px] uppercase">
						程式語言
					</p>
					<h2 className="text-3xl md:text-4xl font-bold">Programming Languages</h2>
				</div>

				<div className='flex flex-col gap-6 px-5'>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaPython className="text-4xl" />
						</div>
						<div className="ml-6">
							Python
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaJava className="text-4xl" />
						</div>
						<div className="ml-6">
							Java
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<IoLogoJavascript className="text-4xl" />
						</div>
						<div className="ml-6">
							JavaScript
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							{/* C++ icon */}
							<svg className="w-9 fill-current" viewBox="0 0 24 24" role="img">
								<path
									d="M22.393 6c-.167-.29-.398-.543-.652-.69L12.925.22c-.508-.293-1.339-.293-1.847 0L2.26 5.31c-.508.293-.923 1.013-.923 1.6v10.18c0 .294.104.62.271.91.167.29.398.543.652.689l8.816 5.091c.508.293 1.339.293 1.847 0l8.816-5.091c.254-.146.485-.399.652-.689s.271-.616.271-.91V6.91c.002-.294-.102-.62-.269-.91zM12 19.109c-3.92 0-7.109-3.189-7.109-7.109S8.08 4.891 12 4.891a7.133 7.133 0 0 1 6.156 3.552l-3.076 1.781A3.567 3.567 0 0 0 12 8.445c-1.96 0-3.554 1.595-3.554 3.555S10.04 15.555 12 15.555a3.57 3.57 0 0 0 3.08-1.778l3.077 1.78A7.135 7.135 0 0 1 12 19.109zm7.109-6.714h-.79v.79h-.79v-.79h-.79v-.79h.79v-.79h.79v.79h.79v.79zm2.962 0h-.79v.79h-.79v-.79h-.789v-.79h.789v-.79h.79v.79h.79v.79z" />
							</svg>
						</div>
						<div className="ml-6">
							C++
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<DiScala className="text-4xl" />
						</div>
						<div className="ml-6">
							Scala
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<GrMysql className="text-4xl" />
						</div>
						<div className="ml-6">
							SQL
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							{/* X.A.N.A. logo */}
							{/* <svg className="w-9 fill-current" viewBox="0 0 24 24" role="img">
                <path
                  d="M82.9 340v-40l-1.6-.5c-1-.4-2-.5-2.4-.3-.4.1-6.1 9.8-12.8 21.6A196 196 0 0 1 53 342.2c-1.7 0-43.6-24.6-43.6-25.6 0-.5 5.2-10.2 11.7-21.4l12.5-22c.8-1.5.5-2-2.6-5a116.2 116.2 0 0 1-29.4-57c-2.2-10.9-2-31 .3-42.3a114.5 114.5 0 0 1 90.3-89.6l3.1-.7.2-39 .2-39h32l.3 39 .2 39 6 1.2c14.6 2.9 25 7 38.2 15.3 9 5.7 15 10.7 22.8 19.4a82.6 82.6 0 0 1 15.9 22.6c7.2 13.4 10.6 24 12.6 39.2a113.7 113.7 0 0 1-31.3 93c-1 1-1.8 2.3-1.8 2.8s5.6 10.6 12.4 22.3a342 342 0 0 1 12.5 22.2c0 1-42 25.6-43.6 25.6-.5 0-6.4-9.4-13-20.8a523 523 0 0 0-13-21.7c-.7-.6-1.6-.7-3-.4l-2 .6v40l-.3 40.1H83v-40zm51.7-60.9a106 106 0 0 0 30.3-14c6-4.3 16.8-14.8 20.4-19.8 4-5.6 11.4-20.1 13.4-26.1 2.2-7 4.7-22 4.7-28.7a128 128 0 0 0-4.7-28.4c-2-6-9.4-20.5-13.6-26.4-6.9-9.8-21.3-21.6-34.5-28.2a106 106 0 0 0-38.5-8.5c-7.4 0-21.5 2.5-29.6 5.2a92.9 92.9 0 0 0-50 42.4c-1.4 2.2-4.1 9.4-6.3 15.9-3.6 11-3.9 12.3-4.3 21-.8 14.8.3 23.7 4.5 36A77.8 77.8 0 0 0 47.8 255a88.4 88.4 0 0 0 66 26.4c11.4-.2 13.9-.4 20.8-2.2zm-26.8-14.4c-12.7-1-29.2-7-38.5-13.8a119 119 0 0 1-18.7-19A96 96 0 0 1 45 221a74 74 0 0 1 59.3-104.3c28-2.9 53.8 9.3 69.4 32.9a71 71 0 0 1 12.5 40.7c0 21.5-8.6 40.7-24.7 55.3a66.1 66.1 0 0 1-23.1 14.2 84.3 84.3 0 0 1-30.7 4.9zm19-21.2a68 68 0 0 0 17.3-8.2c5.8-4 14.6-14.5 17.8-21.4 6.9-15 6.6-32.6-.7-47.9a42.4 42.4 0 0 0-10.3-14c-5.8-6-8-7.7-12.8-10a56.7 56.7 0 0 0-51-.2c-6 3-13.1 8.9-17.4 14.2-3.3 4.3-8.8 15-10.3 20.4a83.5 83.5 0 0 0-.8 23.9c1.5 9.9 9.8 25.3 16.7 30.8 8 6.6 19.2 12.2 26.6 13.4 5.6.8 20.4.3 24.8-1zM106 212.2c-4.2-1-10-5.2-12.4-9a22.4 22.4 0 0 1 0-26c2.4-3.6 8-7.6 12.5-8.9 11.3-3.2 23.5 4 27 15.8 1.4 4.5 1.4 7.8 0 12.3a22.5 22.5 0 0 1-27 15.8z" />
              </svg> */}
							<svg className="w-9 fill-current" viewBox="0 0 750 1000" transform="rotate(35)">
								<path
									d="
  M311.468658,1001.000000 
	C311.066620,999.339966 311.191254,997.679993 311.191498,996.019958 
	C311.199951,936.362427 311.194977,876.704834 311.203308,817.047302 
	C311.203583,815.049500 311.308655,813.051147 311.389069,811.054016 
	C311.520538,807.788818 310.706818,806.138062 306.933167,804.740845 
	C299.360138,801.936768 291.883545,799.416077 283.811249,798.512817 
	C280.030182,798.089722 276.406525,796.363831 272.626495,795.766479 
	C271.457184,795.581665 269.534973,796.854980 268.768982,798.002441 
	C265.618439,802.721924 262.753632,807.633972 259.833435,812.505005 
	C255.222290,820.196472 250.726669,827.958130 246.039062,835.602295 
	C242.314407,841.676147 238.184433,847.506714 234.584488,853.650391 
	C230.803253,860.103333 227.547958,866.862549 223.849899,873.366821 
	C221.714661,877.122437 219.175232,880.649780 216.786514,884.259155 
	C215.051727,886.880493 212.906998,889.299255 211.586273,892.109680 
	C209.576843,896.385681 206.596481,894.186462 204.598785,893.042175 
	C196.053253,888.147339 187.700653,882.907898 179.371643,877.645508 
	C173.458435,873.909424 167.833420,869.703613 161.827698,866.131409 
	C155.486496,862.359741 148.756546,859.245605 142.369339,855.545715 
	C138.369278,853.228638 134.763702,850.240967 130.844757,847.770508 
	C126.104370,844.782349 121.219894,842.022766 116.019897,838.940247 
	C119.226234,833.771667 122.751503,828.263062 126.098442,822.648254 
	C130.012253,816.082520 133.718079,809.393005 137.615936,802.817505 
	C139.972000,798.842957 142.604050,795.032227 144.965378,791.060669 
	C148.925339,784.400452 152.707230,777.634216 156.683731,770.984192 
	C158.965210,767.168762 161.491852,763.500366 163.891495,759.755127 
	C167.604660,753.959778 171.610641,748.316101 174.792328,742.241394 
	C175.585892,740.726257 174.389297,737.147522 172.939911,735.639099 
	C160.459579,722.650146 147.175980,710.393616 135.237961,696.937378 
	C127.698738,688.439392 121.973160,678.315857 115.580849,668.822693 
	C111.351112,662.541138 107.003792,656.288391 103.413750,649.642151 
	C100.490295,644.230042 98.793274,638.170471 96.222168,632.549011 
	C93.501709,626.600952 90.236259,620.902222 87.511581,614.955872 
	C86.012482,611.684265 84.968315,608.173035 84.019989,604.687866 
	C82.720757,599.912964 81.874664,595.013123 80.530563,590.252625 
	C78.720009,583.839966 76.620514,577.508850 74.642433,571.143555 
	C74.593239,570.985229 74.479134,570.841675 74.454010,570.682007 
	C73.318657,563.465942 72.076553,556.264038 71.102936,549.026062 
	C70.521088,544.700623 71.998856,540.030029 68.323555,536.270264 
	C67.627106,535.557861 68.038391,533.705322 68.037453,532.378784 
	C68.024300,513.715088 67.823547,495.047668 68.166100,476.390259 
	C68.256416,471.471313 70.266464,466.616974 70.992188,461.673828 
	C72.268402,452.981018 72.754547,444.146729 74.472092,435.551636 
	C76.020592,427.802521 78.693207,420.269196 81.023491,412.687866 
	C82.923927,406.504974 84.733803,400.262695 87.165398,394.283264 
	C89.683022,388.092194 92.985710,382.224915 95.787727,376.143585 
	C98.286331,370.720642 100.361740,365.095123 103.017700,359.754822 
	C104.980080,355.809052 107.489281,352.121826 109.907303,348.418945 
	C114.625748,341.193176 119.187683,333.843292 124.298309,326.901031 
	C130.756378,318.128387 137.633987,309.660431 144.454391,301.160339 
	C148.442490,296.190033 152.375061,291.115875 156.872116,286.629181 
	C161.851212,281.661621 167.380676,277.233093 172.788467,272.710938 
	C180.030930,266.654572 187.234589,260.533478 194.747360,254.825027 
	C199.767960,251.010193 205.284454,247.844940 210.602478,244.425430 
	C216.952988,240.341995 223.150009,235.973282 229.750000,232.339233 
	C234.973846,229.462875 240.760010,227.626678 246.197296,225.113953 
	C252.303375,222.292191 258.243011,219.108337 264.364410,216.322372 
	C267.425354,214.929276 270.680054,213.887878 273.933472,213.005981 
	C283.002350,210.547714 292.450195,209.081543 301.087372,205.588837 
	C306.271454,203.492508 311.258484,203.600861 316.372650,202.826370 
	C323.858887,201.692657 326.460938,198.451584 326.622284,190.857956 
	C326.940735,175.871002 325.623566,160.606827 328.067566,145.972427 
	C330.772369,129.776215 328.515106,113.405586 331.314758,97.665268 
	C333.968567,82.744949 331.801544,67.810707 334.272430,53.281887 
	C337.186249,36.148651 336.549286,19.066912 337.010681,1.468554 
	C361.354218,1.000000 385.708466,1.000000 410.531372,1.000000 
	C411.131104,7.822898 409.967590,14.984973 411.648743,21.401766 
	C415.816711,37.310455 411.275330,53.621647 414.350952,69.188248 
	C418.448944,89.929283 415.873718,110.747780 417.702393,131.466553 
	C419.382355,150.500107 419.596436,169.662918 420.465302,188.768372 
	C420.533112,190.259323 420.871216,191.764435 420.742737,193.233139 
	C420.241760,198.960419 423.396149,199.664932 428.313019,200.298538 
	C434.993835,201.159470 441.462250,203.655746 448.029938,205.420166 
	C449.141327,205.718735 450.321808,205.769791 451.422882,206.095612 
	C457.616302,207.928406 463.792664,209.818787 469.981689,211.666519 
	C470.933960,211.950836 471.911530,212.229172 472.894775,212.318283 
	C478.935333,212.865738 484.101074,215.752823 489.464752,218.223343 
	C493.007629,219.855209 496.964264,220.590729 500.502106,222.231216 
	C505.724823,224.652985 510.705231,227.591461 515.852783,230.182831 
	C521.203918,232.876678 526.662415,235.357864 532.003052,238.071503 
	C533.342041,238.751831 534.322998,240.105270 535.616699,240.911758 
	C541.022949,244.282272 546.760803,247.190506 551.861328,250.965103 
	C558.997192,256.245850 565.386353,262.550812 572.610474,267.693481 
	C584.789734,276.363586 594.892090,287.182251 604.934021,298.021606 
	C611.250488,304.839569 616.725830,312.463593 622.306091,319.929626 
	C628.716614,328.506470 635.213623,337.062836 640.934814,346.095673 
	C644.967163,352.462036 647.731934,359.626404 651.130859,366.400269 
	C654.048889,372.215790 657.210815,377.913574 659.978577,383.798096 
	C661.558350,387.156738 662.507324,390.807098 663.843445,394.286469 
	C665.747253,399.244293 667.760193,404.160156 669.685547,409.109833 
	C669.918701,409.709198 669.823853,410.428833 670.006287,411.056335 
	C671.968872,417.806335 673.957275,424.548828 675.935608,431.294250 
	C676.028442,431.610962 676.119873,431.931915 676.167908,432.257355 
	C677.359253,440.330353 678.078430,448.506012 679.815613,456.459412 
	C685.769287,483.717712 682.489502,511.246552 682.294128,538.646362 
	C682.233337,547.168335 679.039795,555.666260 677.302551,564.179688 
	C677.037048,565.481079 676.777588,566.801208 676.710083,568.122681 
	C676.318909,575.790283 673.531677,582.810486 670.940796,589.889404 
	C670.655945,590.667786 670.495789,591.494507 670.311523,592.306519 
	C669.152100,597.415833 668.545410,602.732056 666.715149,607.587524 
	C664.297546,614.001160 660.872009,620.030273 657.976929,626.269836 
	C655.680420,631.219177 653.845459,636.407837 651.246277,641.186035 
	C646.475769,649.956177 641.468445,658.610046 636.234192,667.111877 
	C630.745544,676.026794 625.106567,684.869995 619.074951,693.421265 
	C615.927246,697.883850 611.844238,701.698059 608.095154,705.722656 
	C600.410461,713.971985 592.787842,722.285217 584.886597,730.323425 
	C581.719055,733.545837 578.011108,736.290710 574.312927,738.920654 
	C571.442444,740.962036 571.559570,743.416260 573.016968,745.799316 
	C576.848755,752.064941 580.955383,758.162964 584.977173,764.311707 
	C591.086487,773.651978 597.324768,782.910095 603.301819,792.333984 
	C607.659180,799.204163 611.593079,806.341614 615.882385,813.256531 
	C620.004456,819.901855 624.410217,826.370972 628.544739,833.008850 
	C629.950378,835.265564 631.072937,837.737000 632.034790,840.222595 
	C633.401489,843.754456 629.822693,844.647034 628.131165,845.897339 
	C622.191589,850.287720 615.992920,854.366760 609.654358,858.166748 
	C603.375916,861.930786 596.745850,865.103638 590.393005,868.750000 
	C586.561768,870.949036 582.997986,873.608826 579.255310,875.968445 
	C573.243774,879.758423 567.135559,883.396484 561.157471,887.237732 
	C555.214966,891.056152 549.447876,895.151978 543.434326,898.850647 
	C542.496704,899.427368 539.943787,899.056702 539.347900,898.240601 
	C535.181091,892.533447 531.197449,886.678833 527.430115,880.698242 
	C524.974304,876.799622 523.191895,872.476929 520.738586,868.576477 
	C514.550659,858.738464 508.016602,849.117126 501.886108,839.244446 
	C497.358887,831.953674 493.313263,824.365723 488.930664,816.982727 
	C485.830109,811.759705 482.437286,806.710144 479.333893,801.488708 
	C475.680939,795.342590 474.441620,794.922180 467.986267,798.251892 
	C467.693207,798.403076 467.393188,798.574402 467.076355,798.640869 
	C461.613464,799.788025 456.118469,800.799194 450.692566,802.099304 
	C447.267029,802.920105 443.873566,803.997620 440.622375,805.339478 
	C439.781372,805.686646 439.133301,807.444458 439.079926,808.586243 
	C438.893341,812.577087 439.004761,816.581970 439.004608,820.581482 
	C439.002350,880.408569 439.000275,940.235596 438.999084,1000.531372 
	C396.645782,1001.000000 354.291534,1001.000000 311.468658,1001.000000 
  M313.718353,740.934265 
	C315.693634,741.108276 317.730225,741.033020 319.633240,741.500916 
	C326.669861,743.231323 333.584595,745.584412 340.697906,746.832886 
	C345.723999,747.715088 351.481720,745.887573 356.051392,747.586670 
	C363.895233,750.503113 371.646179,749.674805 379.439850,749.409302 
	C386.747375,749.160339 394.025177,748.001831 401.312744,747.212830 
	C412.034607,746.052002 422.753845,744.867371 433.472290,743.675293 
	C433.959808,743.621155 434.427063,743.391296 434.905640,743.249634 
	C441.543243,741.284912 448.237427,739.490723 454.802979,737.309326 
	C461.207214,735.181580 467.594482,732.921265 473.776184,730.229980 
	C479.267334,727.839233 484.519073,724.873779 489.781647,721.989075 
	C496.768463,718.159241 503.797607,714.376831 510.552246,710.163147 
	C515.431580,707.119324 519.966980,703.501160 524.525085,699.970398 
	C532.481873,693.806946 540.589844,687.799744 548.136353,681.160889 
	C552.263123,677.530518 555.313843,672.691956 558.947876,668.482910 
	C564.886047,661.605164 571.259033,655.068054 576.770508,647.868225 
	C580.794434,642.611633 583.759399,636.538940 587.139038,630.796326 
	C590.386719,625.277832 593.806335,619.840271 596.692078,614.135376 
	C599.164307,609.247925 600.944702,604.013855 603.109375,598.966736 
	C605.999084,592.228882 609.361145,585.663086 611.787659,578.765625 
	C614.294922,571.638916 616.339233,564.286804 617.829102,556.880981 
	C619.541321,548.370239 621.412781,539.699829 621.476868,531.085754 
	C621.658875,506.631897 624.231201,482.036163 618.533997,457.798492 
	C617.156738,451.939178 617.009888,445.570648 614.548767,440.244202 
	C611.739441,434.164429 611.467651,427.570801 608.947144,421.556091 
	C607.450378,417.984406 606.245361,414.282837 604.615356,410.776245 
	C601.912903,404.962494 598.949707,399.270477 596.144409,393.503784 
	C594.841675,390.825684 593.917297,387.927917 592.358215,385.417358 
	C588.604065,379.372253 584.892822,373.256165 580.591003,367.605194 
	C572.694641,357.232513 564.992615,346.602142 556.081177,337.142151 
	C546.740295,327.226349 536.379639,318.191010 525.881470,309.473175 
	C518.609741,303.434692 510.521973,298.309540 502.473511,293.298859 
	C496.633179,289.662872 490.280640,286.841827 484.115051,283.739777 
	C481.462128,282.405060 478.733704,281.207367 475.986633,280.075958 
	C472.631836,278.694244 469.229095,277.427399 465.833282,276.147400 
	C461.017883,274.332275 456.272491,272.267151 451.345062,270.830841 
	C445.948944,269.257904 440.398712,268.185577 434.881836,267.060944 
	C424.724976,264.990479 414.607056,261.855927 404.357910,261.305420 
	C386.747345,260.359467 369.035034,260.652252 351.392487,261.238312 
	C342.787811,261.524139 334.226379,263.538757 325.680725,264.980347 
	C321.873535,265.622589 318.150513,266.769806 314.396088,267.714783 
	C313.441467,267.955048 312.492493,268.246277 311.575592,268.603180 
	C307.211975,270.301697 302.905884,272.159851 298.493988,273.719879 
	C291.477631,276.200806 284.115906,277.890991 277.403137,281.001038 
	C267.349274,285.659058 257.526093,290.912262 247.996338,296.571625 
	C241.079086,300.679535 234.224335,305.239471 228.355652,310.689636 
	C218.100555,320.213409 205.677063,327.371887 197.864548,339.657318 
	C193.507812,346.508423 187.906601,352.656494 182.337814,358.628693 
	C172.842850,368.811401 166.463837,380.852753 160.486481,393.195709 
	C156.401627,401.630737 151.810974,409.876465 148.459381,418.596191 
	C144.718948,428.327484 141.423874,438.348083 139.228073,448.521881 
	C137.022125,458.742798 136.403458,469.306671 135.104279,479.722168 
	C135.001694,480.544586 135.305145,481.550140 134.933289,482.186005 
	C131.140945,488.670929 132.957962,495.824799 132.956253,502.566772 
	C132.953537,513.168030 134.097824,523.795044 135.212830,534.363037 
	C136.114426,542.908081 137.567596,551.402832 139.016907,559.878723 
	C139.721848,564.001343 140.959595,568.034363 141.999222,572.096924 
	C142.200806,572.884644 142.625092,573.612854 142.907440,574.382874 
	C144.609177,579.023560 146.341232,583.653687 147.980820,588.316406 
	C149.374542,592.279846 150.239822,596.487610 152.077209,600.224243 
	C155.735107,607.663208 159.915970,614.844238 163.851562,622.147644 
	C167.326889,628.596863 170.202667,635.455627 174.348755,641.436462 
	C180.361298,650.109802 187.172760,658.241821 193.835159,666.449158 
	C204.251617,679.281006 217.291885,689.325134 230.012329,699.650269 
	C235.634247,704.213623 241.840607,708.082581 247.934525,712.030823 
	C253.166611,715.420654 258.540009,718.612427 263.995300,721.630920 
	C267.910004,723.796875 272.025696,725.616089 276.121307,727.433105 
	C279.310333,728.848022 282.582123,730.088318 285.861511,731.284607 
	C289.682434,732.678467 293.566162,733.899414 297.396362,735.268799 
	C302.561523,737.115540 307.701721,739.032288 313.718353,740.934265 
  z"/>
								<path
									d="
  M188.032257,441.761017 
	C189.930557,436.573822 191.724655,431.770447 193.686935,427.036804 
	C194.772049,424.419159 196.413696,422.012543 197.327148,419.347229 
	C200.027740,411.467377 205.368149,405.116150 209.282349,397.960388 
	C219.861969,378.619232 236.232819,364.402740 251.926605,349.761200 
	C259.264038,342.915741 268.701691,338.234589 277.457001,333.025543 
	C283.546478,329.402557 290.184662,326.715027 296.412140,323.307007 
	C302.116394,320.185364 308.116638,318.262604 314.438507,316.798950 
	C319.789703,315.560059 324.874481,313.171051 330.078430,311.294464 
	C330.387238,311.183105 330.701202,311.062927 331.022980,311.013885 
	C347.996918,308.427643 364.993683,306.133209 382.238922,306.963531 
	C386.729736,307.179749 391.249542,307.101074 395.709381,307.586487 
	C404.668365,308.561523 413.677063,309.412354 422.504730,311.136444 
	C427.855743,312.181519 432.852631,314.934265 438.107605,316.609192 
	C442.631500,318.051056 447.338409,318.912598 451.882416,320.299469 
	C454.845154,321.203827 457.853485,322.261566 460.507019,323.819916 
	C468.622681,328.586121 476.580109,333.622070 484.592560,338.563690 
	C490.397125,342.143616 496.610535,345.220184 501.880157,349.466248 
	C507.958954,354.364319 513.236816,360.264862 518.795654,365.797241 
	C523.787537,370.765320 529.286499,375.374237 533.486816,380.951233 
	C539.439331,388.854645 544.702942,397.327576 549.694519,405.887482 
	C553.414551,412.266876 555.923889,419.346161 559.553711,425.786072 
	C562.759521,431.473602 564.932922,437.280243 566.184814,443.720398 
	C567.055481,448.199554 570.064087,452.221741 571.525940,456.643677 
	C572.330261,459.076416 571.416443,462.055115 572.128357,464.544250 
	C575.336487,475.760468 575.363281,487.283173 575.530029,498.745239 
	C575.706604,510.874939 575.481323,523.030212 574.846985,535.143677 
	C574.526550,541.263245 573.265198,547.402588 571.775635,553.369812 
	C570.602417,558.069641 568.371094,562.498962 566.716248,567.086914 
	C565.251892,571.146729 564.058105,575.306946 562.515869,579.335144 
	C561.075806,583.096436 559.366089,586.756470 557.716797,590.434998 
	C556.178528,593.866089 554.714661,597.343689 552.942566,600.653381 
	C550.917908,604.434875 548.712708,608.134216 546.365234,611.724976 
	C542.251709,618.016785 538.460876,624.613953 533.633057,630.320251 
	C527.722900,637.305847 520.949890,643.572144 514.423279,650.021240 
	C510.680969,653.719116 506.937225,657.494629 502.750488,660.645813 
	C495.819183,665.862549 488.587677,670.690369 481.369629,675.512817 
	C476.904724,678.495789 472.324493,681.337280 467.616058,683.913330 
	C464.696808,685.510498 461.464935,686.556641 458.330231,687.735291 
	C452.197601,690.041260 446.006073,692.192383 439.893799,694.550049 
	C435.336090,696.308044 430.866882,698.295227 426.351501,700.164062 
	C425.900299,700.350769 425.408661,700.460999 424.925110,700.544983 
	C418.656891,701.633606 412.416229,703.372681 406.110992,703.653320 
	C393.023315,704.235901 379.901123,704.044006 366.793091,704.164978 
	C366.294495,704.169617 365.793335,704.138306 365.296844,704.088745 
	C353.374756,702.899170 341.400879,702.062439 329.558075,700.329712 
	C325.027191,699.666748 320.838135,696.839905 316.416565,695.207092 
	C312.365326,693.711060 308.294861,691.960327 304.079376,691.296936 
	C299.244507,690.535889 295.406982,688.281128 291.505127,685.682556 
	C289.197815,684.145996 286.449890,683.288635 283.987000,681.964294 
	C279.349030,679.470398 274.646393,677.060974 270.204529,674.247192 
	C264.512726,670.641541 258.864471,666.912415 253.551010,662.781677 
	C249.326996,659.497864 245.548325,655.617920 241.723816,651.848572 
	C234.742661,644.968018 227.514694,638.286804 221.083618,630.916931 
	C216.739563,625.938660 213.620621,619.898438 209.885452,614.379395 
	C207.211487,610.428284 204.244431,606.664307 201.752106,602.605591 
	C200.123886,599.954163 199.123901,596.918884 197.820236,594.065735 
	C194.938995,587.760132 192.041626,581.461853 189.157013,575.157776 
	C188.822418,574.426514 188.474609,573.694763 188.221741,572.933716 
	C186.194000,566.830383 183.958786,560.782043 182.277435,554.583801 
	C181.510696,551.757324 182.307526,548.520325 181.615768,545.657837 
	C176.159637,523.080872 178.287811,500.219910 179.056625,477.442169 
	C179.285431,470.663361 180.587555,463.827637 182.214554,457.219025 
	C183.488815,452.043091 186.023529,447.177460 188.032257,441.761017 
  M429.782471,639.461426 
	C431.839264,638.505310 433.965454,637.673584 435.940918,636.571716 
	C444.805389,631.627625 454.401733,627.596252 462.303986,621.409058 
	C479.182098,608.194214 494.619934,593.329224 504.496643,573.792664 
	C507.108765,568.625732 508.876007,563.037659 511.343750,557.789978 
	C514.018066,552.102905 517.326538,546.683228 517.320007,540.101257 
	C517.319397,539.460327 517.583252,538.817383 517.737366,538.178467 
	C519.439392,531.121094 521.687256,524.143860 522.734436,516.990845 
	C524.889343,502.272217 521.513489,487.768616 520.067444,473.199036 
	C520.002808,472.547821 519.836731,471.893585 519.625671,471.272064 
	C517.795410,465.882141 516.099426,460.439697 514.059387,455.129730 
	C511.552246,448.604187 509.314392,441.878174 505.934906,435.809753 
	C501.865967,428.503357 496.354645,422.010162 492.129089,414.778503 
	C486.508392,405.159119 478.191528,398.139008 470.063477,391.030914 
	C464.455780,386.126923 457.981232,382.114807 451.550446,378.280487 
	C445.602509,374.734070 439.321136,371.665253 432.949982,368.939117 
	C428.730286,367.133575 424.070343,366.377747 419.649048,365.016998 
	C414.329590,363.379913 409.160706,360.977783 403.731262,360.047791 
	C397.724884,359.019012 391.490936,358.932861 385.367493,359.046936 
	C367.772339,359.374634 349.859161,356.716827 333.102478,365.126068 
	C327.443481,367.966034 320.857574,368.991272 315.272522,371.939423 
	C305.128174,377.294312 294.791534,382.637573 285.670776,389.495941 
	C272.105713,399.696167 261.204254,412.700134 252.712433,427.474304 
	C248.982651,433.963470 245.998077,440.885193 242.756622,447.649567 
	C239.681061,454.067810 236.436401,460.450104 236.477310,467.848419 
	C236.480026,468.338806 236.492538,468.987427 236.209900,469.297760 
	C230.557419,475.503937 233.138351,483.723328 231.559006,490.834717 
	C228.403580,505.042694 231.203186,519.693787 233.962936,533.995972 
	C234.145645,534.942749 234.624573,535.831299 234.953949,536.751099 
	C236.557510,541.229431 238.283081,545.669067 239.722672,550.199585 
	C241.163681,554.734497 241.658112,559.688110 243.738281,563.876709 
	C247.726639,571.907654 251.588287,580.318787 257.237000,587.134766 
	C266.128235,597.863403 275.990906,607.915710 286.338257,617.262451 
	C292.958191,623.242249 301.000397,627.761353 308.767426,632.317444 
	C313.928528,635.345093 319.643677,637.509705 325.272430,639.631958 
	C329.486694,641.220886 333.930725,642.241028 338.321930,643.311218 
	C346.451569,645.292358 354.570862,647.374878 362.789337,648.905945 
	C366.497620,649.596741 370.480072,648.689758 374.257629,649.157715 
	C386.757019,650.706299 398.684082,646.468811 410.953949,645.824402 
	C411.904388,645.774414 412.823334,645.181152 413.762756,644.857300 
	C418.838074,643.107727 423.915283,641.363525 429.782471,639.461426 
  z"/>
								<path
									d="
  M359.688354,440.494568 
	C365.279968,439.872467 370.438416,439.277100 376.381042,438.591217 
	C379.002258,438.950104 382.646240,439.848328 386.294159,439.864471 
	C393.641937,439.897003 399.900269,442.468658 406.277740,445.962433 
	C413.026031,449.659332 419.873199,453.173645 424.620514,459.221863 
	C428.950409,464.738251 432.536102,470.899200 435.950409,477.053131 
	C438.337280,481.355103 439.926910,486.106201 441.765564,490.699524 
	C442.118652,491.581726 442.033508,492.662537 442.047302,493.653137 
	C442.093597,496.982117 441.908752,500.324982 442.146149,503.638611 
	C442.795197,512.699463 440.096375,520.792114 436.609100,529.088501 
	C433.813568,535.739319 429.959686,541.385925 425.491699,546.704407 
	C418.821625,554.644165 410.022186,560.092224 400.788818,564.300842 
	C389.068359,569.643005 376.175079,568.796265 363.854614,567.460571 
	C357.445709,566.765747 351.549255,562.002563 345.246368,559.521545 
	C335.120819,555.536011 329.351135,546.711426 322.274475,539.365723 
	C320.375427,537.394470 319.040222,534.653992 318.103760,532.031860 
	C316.519653,527.596130 316.091248,522.660034 314.030396,518.502686 
	C310.966797,512.322449 312.146820,505.966461 311.731201,499.647125 
	C311.127411,490.467255 314.997589,482.400940 318.761536,474.627747 
	C322.299194,467.321838 326.221069,459.824799 333.401825,455.044525 
	C337.711121,452.175751 341.958954,449.181915 346.458923,446.642059 
	C350.567139,444.323303 354.977814,442.540405 359.688354,440.494568 
  z"/>
							</svg>
						</div>
						<div className="ml-6">
							Hoppix
						</div>
					</div>

				</div>
			</div>

			{/* Development Tools */}
			<div className="w-full lgl:w-1/2 px-5">
				<div className="py-12 font-titleFont flex flex-col gap-4">
					<p className="text-sm text-designColor tracking-[4px] uppercase">
						開發工具
					</p>
					<h2 className="text-3xl md:text-4xl font-bold">Development Tools</h2>
				</div>

				<div className="flex flex-col gap-6 px-5">

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaGithub className="text-4xl" />
						</div>
						<div className="ml-6">
							Github / Version Control System
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<SiDjango className="text-4xl" />
						</div>
						<div className="ml-6">
							Django
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaHtml5 className="text-4xl" />
						</div>
						<div className="ml-6">
							HTML
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaReact className="text-4xl" />
						</div>
						<div className="ml-6">
							ReactJS
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<IoLogoFigma className="text-4xl" />
						</div>
						<div className="ml-6">
							Figma
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							{/* Axure icon */}
							<svg className="w-9 fill-current" viewBox="650 500 650 820" role="img">
								<path d="M1202.9,634.5c-4.6-9.7-14.3-15.5-26.1-15.5h-45.6c-13.2,0-23.1,5.3-28.4,14.9l-67.6,98.7l59.2,80.9 l103.3-148.2C1209.1,651.7,1205.1,639.2,1202.9,634.5" />
								<path d="M846.6,632.9L846.6,632.9C836.3,619,822.6,619,818,619h-44.6c-11.4,0-20.8,5.3-25.7,14.4c-5,9.4-4,20.6,3,31 l165.2,229l-190,265c-6.6,10-7.6,21-2.6,30.4c4.9,9.1,14.3,14.4,25.7,14.4h45.6c11.7,0,22-6.1,27.2-15.7l212.7-293L846.6,632.9z" />
								<path d="M1225.6,1160.8L1093,978.9l-58.3,80.4l104.8,139.9l2.7,1.2c4.2,1.9,8.7,2.9,13.2,2.9h44.6 c10.8,0,19.9-4.8,25.1-13.1C1230.5,1181.6,1230.6,1170.9,1225.6,1160.8" />
							</svg>
						</div>
						<div className="ml-6">
							Axure
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaBootstrap className="text-4xl" />
						</div>
						<div className="ml-6">
							BootStrap
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<SiMui className="text-4xl p-0.5" />
						</div>
						<div className="ml-6">
							Material UI
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<FaTrello className="text-4xl" />
						</div>
						<div className="ml-6">
							Trello / Kanban Board
						</div>
					</div>

					<div className="flex items-center text-2xl font-medium">
						<div>
							<SiWireshark className="text-4xl p-0.5" />
						</div>
						<div className="ml-6">
							Wireshark
						</div>
					</div>

				</div>
			</div>
		</motion.div>
	);
}

export default Skills