import React, { useState, useEffect } from 'react'
import animoji from "../../assets/images/alessioAnimoji.png";
import { HiOutlineDocumentDuplicate, HiOutlineMail, HiCheck } from "react-icons/hi";
import './RightBanner.css';

const RightBanner = () => {

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 10000); // Reset after 10 seconds
    }
  }, [isCopied]);

  return (
    <div className=" md:aspect-[1/1.26] max-w-[400px] sml:w-1/2 min-h-[300px] mx-auto px-10 border-50 py-10 rounded-lg shadow-shadowOne flex flex-col items-center justify-center bg-gradient-to-r from-bodyColor to-bgGradientTo group" >
      <img src={animoji} alt="Alessio's animoji" className="w-[100px] rounded-full bg-[#ebce8e] mb-5 " />
      <p className="mx-auto text-xl font-medium font-bodyFont text-white text-center">
        Alessio Wu
      </p>
      <p className="mx-auto text-base font-normal font-bodyFont text-gray-400 text-center mt-1 mb-5">
        Computer science graduate
      </p>
      <p className="mx-auto text-sm font-normal font-bodyFont text-gray-400 text-center mb-5">
        <span className="font-black text-lg glow text-[#43b581]">●</span> &nbsp; Available for new jobs
      </p>
      <div
        onClick={() => window.location = 'mailto:kurehao2001@gmail.com'}
        className="px-5 py-2 bg-black bg-opacity-25 text-gray-200 text-base inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300"
      >
        Contact Me &nbsp; <HiOutlineMail className="text-xl" />
      </div>
      <p className="mx-auto text-sm font-normal font-bodyFont text-gray-400 text-center my-3">
        or
      </p>
      <div
        onClick={() => {
          navigator.clipboard.writeText('kurehao2001@gmail.com');
          setIsCopied(true);
        }}
        className="px-5 py-2 bg-black bg-opacity-10 text-gray-400 text-sm inline-flex items-center justify-center rounded-lg hover:text-white duration-300 cursor-pointer hover:border-[1px] hover:border-designColor border-transparent"
      >
        {isCopied ? 'Email Copied' : 'Copy Email'} &nbsp; {isCopied ? <HiCheck className="text-xl" /> : <HiOutlineDocumentDuplicate className="text-xl" />}
      </div>
    </div>
  );
}

export default RightBanner