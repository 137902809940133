import React from "react";
import Title from "../layouts/Title";
import ProjectsCard from "./ProjectsCard";
import {
  dissertationImages,
  dissertationVideos,
} from "../../assets/projectsMedia/dissertation/index";
import {
  KURFImages,
} from "../../assets/projectsMedia/KURF/index";

const Projects = () => {

  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="VISIT MY PORTFOLIO" des="My Projects" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">

        <ProjectsCard
          title="Greenhouse Gas Emissions and Woodland Changes - KURF"
          des={
            "KURF: King's Undergraduate Research Fellowship." +
            "\n\n" +
            "Analysed remote sensing images raster data using Python, monitoring land use coverage change and greenhouse gas emissions in London over a 30-year period." +
            "\n\n" +
            "Manipulated raster data, covering tasks from setting up the data environment to generating Choropleth maps, Sankey diagrams and other informative visual results."
          }
          images={KURFImages}
        />

        <ProjectsCard
          title="Digital Inequality - Dissertation"
          des={
            "Acquired experience in data collection, cleaning, and analysis techniques, specializing in handling geographical data through Python libraries including GeoPandas, PySAL, statsmodels, mgwr." +
            "\n\n" +
            "Researched on the impact of various socioeconomic factors to digital inequality through various data analysis methodologies, including Ordinary Least Squared (OLS), Spatial Error Model (SEM), Spatial Lag Model (SLM) and Geographically Weighted Regression (GWR)." +
            "\n\n" +
            "Proposed solutions to local governments and organizations for mitigating digital inequality, while also addressing critical literature gaps in the field."
          }
          images={dissertationImages}
          videos={dissertationVideos}
        />

        {/* <ProjectsCard
          title="Project One"
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          images={projectOneImages}
          videos={projectOneVideos}
        /> */}

        {/* <ProjectsCard
          title="E-commerce Website"
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          src={projectTwo}
        /> */}
      </div>
    </section>
  );
};

export default Projects;
