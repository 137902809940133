const imageContext = require.context('./images', false, /\.(png|PNG|jpe?g|JPE?G|svg|SVG)$/);
const KURFImages = imageContext.keys()
  .map(imageContext)
  .sort((a, b) => {
    const aNumber = parseInt(a.match(/\d+/)[0], 10); // Extract and convert numerical part
    const bNumber = parseInt(b.match(/\d+/)[0], 10);
    return aNumber - bNumber;
  });
  
// const videoContext = require.context('./videos', false, /\.(mp4|MP4)$/);
// const KURFVideos = videoContext.keys()
//   .map(videoContext)
//   .sort((a, b) => {
//     const aNumber = parseInt(a.match(/\d+/)[0], 10); // Extract and convert numerical part
//     const bNumber = parseInt(b.match(/\d+/)[0], 10);
//     return aNumber - bNumber;
//   });

export { KURFImages };
