import React, { useState } from 'react'
import Title from '../layouts/Title';
import Education from './Education';
import Skills from './Skills';
import Achievement from './Achievement';
import Experience from "./Experience"
import { HiDownload } from 'react-icons/hi';

const Resume = () => {
  const [educationData, setEducationData] = useState(true);
  const [experienceData, setExperienceData] = useState(false);
  const [skillData, setSkillData] = useState(false);
  const [achievementData, setAchievementData] = useState(false);
  return (
    <section id="cv" className="w-full py-20 border-b-[1px] border-b-black">

      <div className="flex justify-center items-center text-center">
        <Title title="OPEN TO NEW ROLES" des="My CV" />
      </div>

      <div className="flex items-center justify-center mb-14">
        <a className="px-5 py-2 bg-black bg-opacity-25 text-gray-200 text-base inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300"
          href={process.env.PUBLIC_URL + '/Alessio_Wu_CompSci_CV.pdf'} download>
          Download &nbsp; <HiDownload className="text-xl" />
        </a>
      </div>

      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <li
            onClick={() =>
              setEducationData(true) &
              setExperienceData(false) &
              setSkillData(false) &
              setAchievementData(false)
            }
            className={`${educationData
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Education
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setExperienceData(true) &
              setSkillData(false) &
              setAchievementData(false)
            }
            className={`${experienceData
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Experience
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setExperienceData(false) &
              setSkillData(true) &
              setAchievementData(false)
            }
            className={`${skillData ? "border-designColor rounded-lg" : "border-transparent"
              } resumeLi`}
          >
            Professional Skills
          </li>
          <li
            onClick={() =>
              setEducationData(false) &
              setExperienceData(false) &
              setSkillData(false) &
              setAchievementData(true)
            }
            className={`${achievementData
              ? "border-designColor rounded-lg"
              : "border-transparent"
              } resumeLi`}
          >
            Others
          </li>
          {/* <li className="border-transparent resumeLi">
            <a className="text-designColor" href={process.env.PUBLIC_URL + '/Alessio_Wu_CompSci_CV.pdf'} download>
              Download
            </a>
          </li> */}
        </ul>
      </div>
      {educationData && <Education />}
      {experienceData && <Experience />}
      {skillData && <Skills />}
      {achievementData && <Achievement />}

    </section >
  );
}

export default Resume