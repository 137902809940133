import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col items-center lgl:items-start lgl:flex-row gap-10 lgl:gap-20"
    >

      <div class className="max-w-[600px] w-full lgl:w-1/2">
        <div className="pt-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2016 - 2020</p>
          <h2 className="text-3xl md:text-4xl font-bold">Qualifications</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full flex flex-col gap-10">
          <div className="w-full bg-black bg-opacity-20 hover:bg-opacity-30 duration-300 rounded-lg p-8 lgl:py-20 lgl:px-10 flex flex-col justify-center gap-12 shadow-shadowOne">

            {/* IELTS */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    IELTS
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    2020
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    7.0
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                International English Language Testing System
              </p>
            </div>

            {/* UKMT */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    UKMT
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    2019 & 2018
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Bronze
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                UK Mathematics Trust
              </p>
            </div>

            {/* ECDL */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    ECDL
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    2018
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Pass
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                European Computer Driving Licence
              </p>
            </div>

            {/* DELF */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    DELF A1
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    2016
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Pass
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                Diplôme d’Études en Langue Française
              </p>
            </div>

          </div>
        </div>
      </div>

      <div class className="max-w-[600px] w-full lgl:w-1/2">
        <div className="pt-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            語言
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Languages</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full flex flex-col gap-10">
          <div className="w-full bg-black bg-opacity-20 hover:bg-opacity-30 duration-300 rounded-lg p-8 lgl:py-20 lgl:px-10 flex flex-col justify-center gap-12 shadow-shadowOne">

            {/* English */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    English
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    {/* 2020 */}
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Advanced
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                {/* International English Language Testing System */}
              </p>
            </div>

            {/* Chinese */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    Chinese (Mandarin)
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    {/* 2019 & 2018 */}
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Native
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                {/* UK Mathematics Trust */}
              </p>
            </div>

            {/* Italian */}
            <div>
              <div className="flex flex-col sm:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                <div>
                  <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                    Italian
                  </h3>
                  <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                    {/* 2018 */}
                  </p>
                </div>
                <div>
                  <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                    Upper Intermediate
                  </p>
                </div>
              </div>
              <p className="text-sm md:text-base font-medium text-gray-400 mt-2 group-hover:text-gray-300 duration-300">
                {/* European Computer Driving Licence */}
              </p>
            </div>

          </div>
        </div>
      </div>

    </motion.div>
  );
};

export default Achievement;
