import React from "react";
import { AiFillFormatPainter, AiFillCode, AiFillLayout } from "react-icons/ai";
import { FaDatabase, FaPython, FaGithubSquare, FaTrello } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      {/* <div className="flex justify-center items-center text-center"> */}
      <Title title="Features" des="What I Do" />
      {/* </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Software Development"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<AiFillCode />}
        />
        <Card
          title="Data Analysis"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<FaDatabase />}
        />
        <Card
          title="UI/UX Design"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<AiFillLayout />}
        />
        <Card
          title="Programming Lang"
          des="
          Python, Java, JavaScript, C++, Scala, SQL"
          icon={<FaPython />}
        />
        <Card
          title="Development Tools"
          des="Github/Version Control System, Django, HTML, ReactJS, Figma, Axure, BootStrap, Material UI, Trello/Kanban Board, Wireshark"
          // icon={<FaTrello />}
          icon={<FaGithubSquare />}
        />
        {/* <Card
          title="Mobile Development"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<FaMobile />}
        />
        <Card
          title="UX Design"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<SiAntdesign />}
        />
        <Card
          title="Hosting Websites"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          hic consequuntur eum repellendus ad."
          icon={<FaGlobe />}
        /> */}
      </div>
    </section>
  );
};

export default Features;
