import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";

import { BsGithub } from "react-icons/bs";
import { FaGlobe, FaAngleDown, FaEye, FaExpand } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaMinimize, FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ProjectCard.css";

const ProjectsCard = ({ title, des, images = [], videos = [] }) => {
  const [showDiv, setShowDiv] = useState(false);
  const outsideContentRef = useRef(null);
  const carouselRef = useRef(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [currImage, setCurrImage] = useState(images[0]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleClick = (e) => {
    setShowDiv(!showDiv);
  };

  const handleOutsideClick = (e) => {
    if (
      outsideContentRef.current &&
      !outsideContentRef.current.contains(e.target) &&
      fullscreen === false
    ) {
      setShowDiv(false);
    }
  };

  const closeDiv = () => {
    setShowDiv(false);
  };

  const expandedDivContentvariants = {
    initial: {
      opacity: 0,
      // scale: 0.5,
      // transition: { type: "spring", stiffness: 20, damping: 50, duration: 0.5 },
    },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.3 },
  };

  const expandedDivVariants = {
    initial: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.5 },
    },
    visible: { opacity: 1, scale: 1 },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.5 },
    },
  };

  const sliderRef = useRef(null);

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    adaptiveHeight: true,
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    ref: sliderRef,
  };

  // console print images and videos
  console.log(images);
  console.log(videos);

  // const media = images.map((image) =>
  //   <div key={image} onClick={() => { setFullscreen(!fullscreen); setCurrImage(image); }}>
  //     <img className={"w-full h-full cursor-pointer"} src={image} alt={image} />
  //   </div >
  // )

  const media = (
    images.length > 0 ? images.map((image) => (
      <div key={image} onClick={() => { setFullscreen(!fullscreen); setCurrImage(image); }}>
        <img className={"w-full h-full cursor-pointer"} src={image} alt={image} />
      </div >
    )).concat(
      videos.length > 0 ? videos.map((video) => (
        <div key={video}>
          <video className="w-full h-full" controls autoPlay muted>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      ))
        : []
    ) : null
  );


  return (
    <>
      <div
        className={`w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-bgGradientTo group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000 cursor-pointer ${showDiv ? "fade-out" : "spring"
          }`}
        onClick={handleClick}
      >
        <div className="w-full h-full overflow-hidden rounded-lg">
          <img
            className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
            src={images[0]}
            alt="thumbnail"
          />
        </div>

        <div className="w-full mt-5 flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-bold">
              {title}
            </h3>
          </div>
          {/* <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
              {des}
            </p> */}
        </div>
      </div>

      {/* Expanded card */}
      {showDiv && (
        <motion.div
          className="fixed bottom-0 left-0 w-full h-screen p-6 md:p-12 flex items-start justify-center bg-black bg-opacity-30 backdrop-grayscale backdrop-blur-md z-50 overflow-y-auto"
          initial="initial"
          animate="visible"
          exit="exit"
          variants={expandedDivVariants}
        >
          <div ref={outsideContentRef}>
            <div className="w-full justify-center z-50 p-4">
              <motion.div
                className="max-w-[90vw] lg:max-w-[800px] p-4 md:px-12 h-auto md:py-10 rounded-lg shadow-shadowOne flex-col justify-center bg-gradient-to-r from-bodyColor to-bgGradientTo"
                initial="initial"
                animate="visible"
                exit="exit"
                variants={expandedDivContentvariants}
              >

                <div className="overflow-hidden">

                  <div className="mb-5 flex items-center justify-end gap-2">
                    {/* <div
                      className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"

                      onClick={handlePrevSlide}
                    >
                      <FaChevronLeft />
                    </div>
                    <div
                      className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"

                      onClick={handleNextSlide}
                    >
                      <FaChevronRight />
                    </div> */}
                    <div
                      className="text-3xl w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"
                      onClick={handleClick}
                    >
                      <IoClose />
                    </div>
                  </div>
                  <Carousel
                    dynamicHeight={true}
                    infiniteLoop={true}
                    showIndicators={false}
                    showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        // <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        //   -
                        // </button>
                        <div
                          // className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"
                          className="absolute z-10 top-[calc(50%-15px)] left-[15px] w-10 h-10 cursor-pointer text-lg rounded-full opacity-50 bg-black inline-flex justify-center items-center text-gray-400 hover:opacity-100 hover:text-designColor duration-300"
                          onClick={onClickHandler}
                        >
                          <FaChevronLeft />
                        </div>
                      )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                        // <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        //   +
                        // </button>
                        <div
                          // className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"
                          className="absolute z-10 top-[calc(50%-15px)] right-[15px] w-10 h-10 cursor-pointer text-lg rounded-full opacity-50 bg-black inline-flex justify-center items-center text-gray-400 hover:opacity-100 hover:text-designColor duration-300"
                          onClick={onClickHandler}
                        >
                          <FaChevronRight />
                        </div>
                      )
                    }
                  >
                    {/* <Slider {...sliderSettings}> */}
                    {media}
                    {/* <div>
                        <img className="w-full h-full" src={src} alt="src1" />
                      </div>
                      <div>
                        <video className="w-full h-full" controls autoPlay muted>
                          <source
                            src={
                              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4 "
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div> */}
                    {/* </Slider> */}
                  </Carousel>
                </div>

                <div className="mt-5 w-full flex flex-col gap-6">
                  <div>
                    <div className="flex items-center justify-start">
                      <h3 className="text-lg uppercase text-designColor font-bold">
                        {title}
                      </h3>
                    </div>
                    <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300 overflow-auto" style={{ whiteSpace: "pre-wrap" }}>
                      {des}
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}

      {fullscreen && (
        <motion.div
          className="fixed bottom-0 left-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-30 backdrop-grayscale backdrop-blur-md z-50"
          initial="initial"
          animate="visible"
          exit="exit"
          variants={expandedDivVariants}
        >
          <div onClick={() => { setFullscreen(!fullscreen) }}>
            <img className="w-[100vw] h-[100vh] object-contain" src={currImage} alt="" />
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ProjectsCard;
