import React from "react";
import { motion } from "framer-motion";
import ExperienceCard from "./ExperienceCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col items-center gap-10 lgl:gap-20"
    >
      {/* Software Engineering */}
      <div class className="max-w-[600px]">
        <div className="pt-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            軟體工程
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Software Engineering
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">

          <ExperienceCard
            title="Large Web Application Development - Group Project"
            subTitle="Jan - Apr 2022, London"
            des={
              "Developed in Python and JavaScript a feature-rich movie club web application within an 8-member team, demonstrating strong collaborative skills." +
              "\n\n" +
              "Gained exposure in the frontend environment by creating web pages with React, Figma and Material-UI." +
              "\n\n" +
              "Improved group collaboration skills with Kanban board, ensuring timely task delivery and effective communication."
            }
          />

        </div>
      </div>

      {/* Data Analysis */}
      <div class className="max-w-[600px]">
        <div className="py-6 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            數據分析
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Data Analysis</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">

          <ExperienceCard
            title="Data Analyst/Scientist - King’s Undergraduate Research Fellowship"
            subTitle="Jun 2023 - Oct 2023, London"
            des={
              "Analysed remote sensing images raster data using Python, monitoring land use coverage change and greenhouse gas emissions in London over a 30-year period." +
              "\n\n" +
              "Manipulated raster data, covering tasks from setting up the data environment to generating Choropleth maps, Sankey diagrams and other informative visual results."
            }
          />

          <ExperienceCard
            title="Digital Inequality - Final Year Dissertation"
            subTitle="Sep 2022 - Apr 2023, London"
            des={
              "Acquired experience in data collection, cleaning, and analysis techniques, specialising in handling geographical data through Python libraries including GeoPandas, PySAL, statsmodels, mgwr." +
              "\n\n" +
              "Researched on the impact of 6 socioeconomic factors to digital inequality through 4 data analysis methodologies, including Spatial Lag Model and Geographically Weighted Regression." +
              "\n\n" +
              "Proposed 5 actionable suggestions to local governments and organisations for mitigating digital inequality, while also addressing critical literature gaps in the field." +
              "\n\n" +
              "Presented the dissertation at the Regional Studies Conference, a global gathering of academics and policymakers focusing on regional and urban research, development and policy."
            }
          />

        </div>
      </div>

      {/* UI/UX Research & Design */}
      <div class className="max-w-[600px]">
        <div className="py-6 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            UI/UX研究&設計
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">
            UI/UX Research & Design
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">

          <ExperienceCard
            title="Flat Finding Web Application - Group Project"
            subTitle="Jan - Apr 2023, London"
            des={
              "Conducted UX research by interviewing 25 individuals searching for flats online, empathising with users’ needs and challenges. Analysed and coded the interviews to identify key problem statements." +
              "\n\n" +
              "Conceptualised 18 features and developed an interactive flat-finding web application prototype using Axure, to enhance the user experience and efficiency in the flat searching process."
            }
          />

        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
